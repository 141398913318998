import React from 'react';
//Material UI
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root:{
    alignItems: 'center',
    background: theme.palette.common.icons,
    borderRadius: '35px',
    color: 'white',
    display: 'flex',
    height: '35px',
    justifyContent: 'center',
    marginRight: '0.5rem',
    padding: '5px',
    width: '35px',
  },
  img:{
    width: '80%',
  }
}));

export default ({Icon}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img className={classes.img} src={Icon} alt=""/>
    </div>
  );
};