import React, { Fragment } from 'react';
//Components
import Header from './header';
//Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
//Stles
import '../styles/layout.css';
import '../../static/fonts/autotokio/nissanBrand.css';
import '../../static/fonts/chs/stratumGMC.css';
import '../../static/fonts/infiniti/infiniti.css';
import '../../static/fonts/toro/louis.css';
import '../../static/fonts/volker/vw.css';

export default ({ brand, children, contact, header }) => (
  <Fragment>
    <CssBaseline />
    <div className="layout">
      {header ? <Header contact={contact} logo={brand.logos.header} /> : false}
      <main className="layout_main">{children}</main>
    </div>
  </Fragment>
);
