import React from 'react';
//Components
import Container from './container';
//Img
import fb from '../images/fb.svg';
import ig from '../images/ig.svg';
//Material UI
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.header,
    color: '#ffffff',
    display: 'flex',
    position: 'fixed',
    width: '100%',
    zIndex: 6141234499,
  },
  fb: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '0.5rem',
  },
  ig: {
    alignItems: 'center',
    display: 'flex',
    borderRight: '3px solid white',
    paddingRight: '0.5rem',
  },
  img: {
    height: '30px',
  },
  logo: {
    alignItems: 'center',
    display: 'flex',
    height: '67px',
  },
  social: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default ({ contact, logo }) => {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      <Container justifyContent="spaceBetween">
        <div className={classes.logo}>
          <img className={classes.img} alt="logo" src={logo} />
        </div>
        <div className={classes.social}>
          <a
            className={classes.ig}
            href={contact.instagram}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className={classes.img} alt="instagram" src={ig} />
          </a>
          <a
            className={classes.fb}
            href={contact.facebook}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className={classes.img} alt="facebook" src={fb} />
          </a>
        </div>
      </Container>
    </header>
  );
};
